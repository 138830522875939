<template>
  <div class="fixed flex flex-col left-0 top-0 right-0 py-8 px-4 bg-white bottom-[73px] lg:bottom-0" @click.stop>
    <div class="flex justify-center items-center border-b pb-2 mb-4">
      <h2 class="text-center font-bold ltr:ml-auto rtl:mr-auto">{{ t('common.search') }}</h2>
      <Icon name="tabler:x" size="18" class="cursor-pointer ltr:ml-auto rtl:mr-auto" @click="emit('close')" />
    </div>
    <div class="flex items-center gap-x-4">
      <input
        v-model="searchQuery"
        type="text"
        name="search"
        id="search"
        class="w-full text-sm py-2 pl-4 pr-8 outline-none bg-primary-100 rounded-md"
        :placeholder="t('forms.placeholders.search')"
      />
      <button
        v-if="searchQuery"
        @click="searchQuery = ''"
        class="absolute text-lg text-gray-600 ltr:right-6 rtl:left-6"
      >
        <Icon v-if="searchQuery && !isSearchLoading" name="tabler:circle-x" size="18" class="text-primary-1000" />
        <div v-else>
          <span class="uppercase text-xs mr-2 text-primary-1000 font-bold whitespace-nowrap">{{
            t('common.searching')
          }}</span>
          <Icon name="tabler:loader-2" size="18" class="text-primary-1000 animate-spin" />
        </div>
      </button>
    </div>

    <div v-if="searchQuery && filteredProducts.length" class="w-full h-full mt-1 mb-4 z-10 overflow-y-auto">
      <!-- Categories Section -->
      <div v-if="filteredProducts.some((item) => item.type === 'category')">
        <h3 class="text-primary-1000 font-bold mb-2 border-b text-xs uppercase mt-2">
          {{ t('pages.shop.categories') }}
        </h3>
        <ul>
          <template v-for="item in filteredProducts.filter((item) => item.type === 'category')" :key="item.id">
            <NuxtLink :to="generateLink(`/shop?category=${item.slug}`)" @click="handleSearchProductClick(item)">
              <li class="text-sm font-medium py-1 px-4">
                <div class="flex-grow">
                  <span
                    v-for="(part, index) in highlightMatch(item.name, searchQuery)"
                    :key="index"
                    :class="{ 'bg-primary-200 font-bold': index === 1 }"
                  >
                    {{ part }}
                  </span>
                </div>
              </li>
            </NuxtLink>
          </template>
        </ul>
      </div>

      <!-- Products Section -->
      <div v-if="filteredProducts.some((item) => item.type === 'product')">
        <h3 class="w-full text-primary-1000 font-bold mb-2 border-b text-xs uppercase mt-2">
          {{ t('common.products') }}
        </h3>
        <ul>
          <template v-for="item in filteredProducts.filter((item) => item.type === 'product')" :key="item.id">
            <NuxtLink :to="generateLink(`/product/${item.slug}`)" @click="handleSearchProductClick(item)">
              <li
                class="flex items-center p-2 hover:bg-gray-100 cursor-pointer shadow-sm border border-primary-400 bg-white rounded-lg my-3 mx-3 text-sm font-medium"
              >
                <img :src="item.thumbnail" alt="" class="w-10 h-10 mr-2 object-contain rounded-md" />
                <div>
                  <span
                    v-for="(part, index) in highlightMatch(item.name, searchQuery)"
                    :key="index"
                    :class="{ 'bg-primary-200 font-bold': index === 1 }"
                  >
                    {{ part }}
                  </span>
                </div>
              </li>
            </NuxtLink>
          </template>
        </ul>
      </div>
    </div>

    <div v-if="trendingProducts?.data && searchQuery === ''" class="mt-auto lg:mb-0">
      <h3 class="text-primary-1000 font-bold mb-4">{{ t('common.trending_products') }}</h3>
      <SharedNavProductCard
        :product="product"
        v-for="product in trendingProducts?.data.slice(0, 3)"
        is-search-sidebar
        class="py-2 px-1"
        :key="product.id"
        @click="
          () => {
            gtm?.trackEvent({
              event: 'view_item',
              navigated_from: 'Search Trending Products',
              ecommerce: {
                currency: 'AED',
                value: formatPrice(product.price),
                items: [
                  {
                    item_id: product.id,
                    item_sku: product.sku,
                    item_name: product.meta_title['en'],
                    item_category: product.categories?.[0]?.name,
                    item_category2: product.categories?.[1]?.name,
                    item_category3: product.categories?.[2]?.name,
                    price: formatPrice(product.price),
                    discount: formatPrice(product.sale_price),
                    quantity: 1,
                  },
                ],
              },
            });

            viewProduct({
              LanguageCode: locale,
              ProductName: product.name,
              ProductID: product.id,
              SKU: product.sku,
              Categories: product.categories?.map((category) => category.name).join(', '),
              ImageURL: product.cover,
              URL: route.fullPath,
              Brand: 'Biogena',
              Price: product.price,
              PriceCurrency: 'AED',
              CompareAtPrice: product.sale_price,
            });

            emit('close');
          }
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import lodash from 'lodash';
import type { Product, ProductsFetchResponse } from '~/interfaces/Product';
const { debounce } = lodash;

const { t, locale } = useI18n();
const gtm = useGtm();
const route = useRoute();

const emit = defineEmits(['close']);

const searchQuery = ref('');

const { viewProduct } = useKlaviyo();
const productStore = useProductStore();
const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);
const { searchProducts, isSearchLoading } = storeToRefs(productStore);

const closeDropdown = () => {
  searchQuery.value = '';
};

const handleClickOutside = (event: any) => {
  if (!event.target.closest('.search-dropdown')) {
    closeDropdown();
  }
};

const handleSearchProductClick = (product: Product) => {
  gtm?.trackEvent({
    event: 'view_item',
    navigated_from: 'Search',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(product.price),
      items: [
        {
          item_id: product.id,
          item_sku: product.sku,
          item_name: product.name,
          item_category: product.categories?.[0]?.name,
          item_category2: product.categories?.[1]?.name,
          item_category3: product.categories?.[2]?.name,
          price: formatPrice(product.price),
          discount: formatPrice(product.sale_price),
          quantity: 1,
        },
      ],
    },
  });

  viewProduct({
    LanguageCode: locale.value,
    ProductName: product.name,
    ProductID: product.id,
    SKU: product.sku,
    Categories: product.categories?.map((category) => category.name).join(', '),
    ImageURL: product.cover,
    URL: window.location.href,
    Brand: 'Biogena',
    Price: product.price,
    PriceCurrency: 'AED',
    CompareAtPrice: product.sale_price,
  });

  emit('close');
};

const highlightMatch = (text: string, query: string) => {
  const matchIndex = text.toLowerCase().indexOf(query.toLowerCase());
  if (matchIndex < 0) return [text];

  const length = query.length;
  return [
    text.substring(0, matchIndex),
    text.substring(matchIndex, matchIndex + length),
    text.substring(matchIndex + length),
  ];
};

const fetchSearchProducts = async (query: string) => {
  gtm?.trackEvent({
    event: 'search',
    search_term: query,
  });

  isSearchLoading.value = true;
  await useFetch<ProductsFetchResponse>('/api/products/search/' + query, {
    key: 'search-products',
    headers: {
      'Accept-Language': locale.value.split('-')[0],
    },
    onResponse({ response }) {
      productStore.setSearchProducts(response._data.data);
    },
  }).finally(() => {
    isSearchLoading.value = false;
  });
};

const debouncedSearch = debounce(fetchSearchProducts, 300);

watch(searchQuery, (newValue) => {
  if (newValue && newValue.trim()) {
    debouncedSearch(newValue);
  } else {
    debouncedSearch.cancel();
  }
});

function normalizeTerm(term: string) {
  return term.toLowerCase().replace(/s$/, ''); // Removes trailing 's'
}

const filteredProducts = computed(() => {
  let combinedResults = [];

  if (searchProducts.value) {
    combinedResults = combinedResults.concat(
      searchProducts.value.map((product) => ({
        type: 'product',
        ...product,
      })),
    );
  }

  if (categories.value) {
    const queryNorm = normalizeTerm(searchQuery.value);
    combinedResults = combinedResults.concat(
      categories.value
        .filter((category) => {
          const categoryName = normalizeTerm(category.name);
          return categoryName.includes(queryNorm);
        })
        .map((category) => ({
          type: 'category',
          ...category,
        })),
    );
  }

  return combinedResults.sort((a, b) => {
    const nameA = normalizeTerm(a.name);
    const nameB = normalizeTerm(b.name);
    const startsWithA = nameA.startsWith(normalizeTerm(searchQuery.value));
    const startsWithB = nameB.startsWith(normalizeTerm(searchQuery.value));

    if (startsWithA && !startsWithB) {
      return -1;
    } else if (!startsWithA && startsWithB) {
      return 1;
    } else {
      return nameA.localeCompare(nameB);
    }
  });
});

onMounted(async () => {
  window.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  window.removeEventListener('click', handleClickOutside);
});

const { data: trendingProducts } = await useLazyFetch<ProductsFetchResponse>('/api/products/tags/' + 'trending', {
  key: 'search-trending-products',
  headers: {
    'Accept-Language': locale.value.split('-')[0],
  },
  transform(input) {
    return {
      ...input,
      fetchedAt: new Date(),
      language: locale.value,
    };
  },
  getCachedData(key, nuxtApp) {
    const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

    if (!data) {
      return;
    }

    const expirationDate = new Date(data.fetchedAt);
    expirationDate.setTime(expirationDate.getTime() + 3600 * 1000);
    const isExpired = expirationDate.getTime() < Date.now();

    if (isExpired) {
      return;
    }

    if (locale.value !== data.language) {
      return;
    }

    return data;
  },
});

if (trendingProducts.value?.data && trendingProducts?.value?.data?.length > 1) {
  trendingProducts.value?.data?.sort(() => Math.random() - 0.5);
}
</script>
